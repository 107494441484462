<script setup lang="ts">
    import Paginate from 'vuejs-paginate-next';
    const emit = defineEmits(['click']);

    const props = defineProps({
  pagination: null
});
    const page = ref(props.pagination.currentPage);

    const clicked = (page: number) => {
        emit('click', page);
    };
</script>

<template>
    <paginate
        v-model="page"
        :page-count="pagination.lastPage"
        :click-handler="clicked"
        :prev-text="$t('pagination.prev')"
        :next-text="$t('pagination.next')"
        container-class="flex justify-center w-full p-4"
        page-link-class="px-4 py-2 mx-1 text-neutral-600 transition-all rounded cursor-pointer bg-neutral-100 hover:bg-neutral-200"
        prev-link-class="px-4 py-2 mx-1 text-neutral-600 transition-all rounded cursor-pointer bg-neutral-100 hover:bg-neutral-200"
        next-link-class="px-4 py-2 mx-1 text-neutral-600 transition-all rounded cursor-pointer bg-neutral-100 hover:bg-neutral-200"
        active-class="!bg-primary !text-white hover:!bg-primary-light"
        :no-li-surround="true"
        :hide-prev-next="true"
        v-if="pagination.lastPage > 1"
    />
</template>
